import { FC } from 'react'

import { Form } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import { InfoCell } from '../../../../components'
import { BoxInfo } from '../../../../components/BoxInfo'
import { BoxWrapper } from '../../../../components/BoxWrapper'
import { DatePicker } from '../../../../components/Form/DatePicker'
import { fSchemaActions } from '../../../../features/formulaSchema'
import { selectPkCodesDropdown } from '../../../../features/formulaSchema/selectors'

import { IOtherTestedPackForm } from '../types'

import { TEST_RESULT_DROPDOWN, WEIGHT_LOSS_DROPDOWN } from './constants'
import { IOtherTestedPackagingModalContentProps } from './types'

export const OtherTestedPackagingModalContent: FC<
  IOtherTestedPackagingModalContentProps
> = ({ data, onUpdate, defaultDropdown = [] }) => {
  const pkCodesDropdownList = useSelector(selectPkCodesDropdown)
  const dispatch = useDispatch()

  const pkcodesDropdowns = pkCodesDropdownList?.length
    ? pkCodesDropdownList
    : defaultDropdown

  const changeHandler: (arg: { key: keyof IOtherTestedPackForm; value: any }) => void = ({
    key,
    value
  }) => {
    let newData = { ...data }

    if (key === 'pkcodesUuid') {
      const selectedPk = pkcodesDropdowns.find(({ value: dropVal }) => dropVal === value)
      newData['sideCollectorId'] = selectedPk?.sideCollectorId || null
      newData['category'] = selectedPk?.packageCategory || ''
    }

    newData = {
      ...newData,
      [key]: value
    }

    onUpdate(newData)
  }
  // const [packageCategory, setPackageCategory] = useState(defaultCategory)

  const searchHandler = async (value: string) => {
    if (value && value.length > 2) {
      dispatch(fSchemaActions.getPKCodeDropdownItems({ pkCode: value }))
    }
  }

  return (
    <Form layout="vertical">
      <BoxWrapper enablePadding>
        <InfoCell
          label="Test Number"
          value={data.testNumber || ''}
          isEditing
          inputConfig={{
            onChange: (value: string) => changeHandler({ key: 'testNumber', value })
          }}
        />
        <InfoCell
          label="Tested Code"
          value={data.testedCode || ''}
          isEditing
          inputConfig={{
            onChange: (value: string) => changeHandler({ key: 'testedCode', value })
          }}
        />
        <DatePicker
          label="Test Start Date"
          value={data.testStartDate ? moment(data.testStartDate) : undefined}
          onChange={(value) => changeHandler({ key: 'testStartDate', value })}
          required
          error={'testStartDate' in data && !data.testStartDate}
          errorMessage="Test Start Date is required"
        />
        <InfoCell
          label="Test Result"
          value={data.testResult || ''}
          isEditing
          inputType="select"
          inputConfig={{
            items: TEST_RESULT_DROPDOWN,
            onChange: ({ value }: Record<string, any>) =>
              changeHandler({ key: 'testResult', value }),
            error: 'testResult' in data && !data.testResult,
            errorMessage: 'Test Result is required',
            required: true
          }}
        />
        <InfoCell
          label="Weight Loss Test"
          value={data.weightLossTest || ''}
          isEditing
          inputType="select"
          inputConfig={{
            items: WEIGHT_LOSS_DROPDOWN,
            onChange: ({ value }: Record<string, any>) =>
              changeHandler({ key: 'weightLossTest', value }),
            error: 'weightLossTest' in data && !data.weightLossTest,
            errorMessage: 'Weight Loss Test is required',
            required: true
          }}
        />
        <InfoCell
          label="Min % Volatiles In Specific"
          value={
            data.minPercentVolatilesInSpecific === undefined
              ? null
              : data.minPercentVolatilesInSpecific
          }
          isEditing
          inputType="number"
          inputConfig={{
            onChange: (value: number) =>
              changeHandler({ key: 'minPercentVolatilesInSpecific', value }),
            placeholder: 'Insert Value',
            hideSideBtn: true,
            min: 0,
            max: 9999.99,
            step: 0.01
          }}
        />
        <InfoCell
          label="Max % Volatiles In Specific"
          value={
            data.maxPercentVolatilesInSpecific === undefined
              ? null
              : data.maxPercentVolatilesInSpecific
          }
          isEditing
          inputType="number"
          inputConfig={{
            onChange: (value: number) =>
              changeHandler({ key: 'maxPercentVolatilesInSpecific', value }),
            placeholder: 'Insert Value',
            hideSideBtn: true,
            min: 0,
            max: 9999.99,
            step: 0.01
          }}
        />
        <InfoCell
          label="Test Material"
          value={data.materials || ''}
          isEditing
          inputConfig={{
            onChange: (value: string) => changeHandler({ key: 'materials', value })
          }}
        />

        <InfoCell
          label="PK Intercos Code"
          value={data.pkcodesUuid}
          isEditing
          inputType="select"
          inputConfig={{
            items: pkcodesDropdowns,
            onChange: ({ value }: Record<string, any>) =>
              changeHandler({ key: 'pkcodesUuid', value }),
            onSearch: searchHandler,
            filterOption: false,
            withLookup: true,
            placeholder: 'Start typing to see results'
          }}
        />
        <BoxInfo label="Packaging Category" value={get(data, 'category', '')} />
      </BoxWrapper>
    </Form>
  )
}
