import { SizeType } from 'antd/lib/config-provider/SizeContext'

const CDays = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
] as const

export const RDays: ReadonlyArray<string> = CDays

export interface IDatePickerProps {
  required?: boolean
  disabled?: boolean
  value?: any
  label?: string
  error?: boolean
  placeholder?: string
  onBlur?: () => void
  onChange: (date: any) => void
  enableTooltip?: boolean
  message?: string
  size?: SizeType
  minDays?: number
  excludeDays?: Array<typeof CDays[number]>
  isOpen?: boolean
  className?: string
  maxDate?: moment.Moment | null
  minDate?: moment.Moment | null
  format?: string
  errorMessage?: string
}
