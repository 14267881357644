import { FC, useState } from 'react'

import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

import AttachSection from '../../../containers/AttachSection'
import { packagingActions } from '../../../features/packaging'
import { AttachmentFilter } from '../../../features/packaging/model'
import { getPackageDetailVariant } from '../../../features/packaging/selectors'
import { selectPermissions, selectUserRole } from '../../../features/users/selectors'
import { filterAttachmentsByPermissions } from '../../../utils/attachmentsHelper'

import { IAttachmentInVariantProps } from './types'
import { ATTACHMENTS_IN_DETAIL_TABLE_CONFIG } from '../../../components/Packaging/AttachmentsTable/constants'
import { canUserAddVariant } from '../../../auth-msal'

const AttachmentInVariant: FC<IAttachmentInVariantProps> = ({
  isOnEdit,
  list,
  variantId,
  packageId
}) => {
  const userRoles = useSelector(selectUserRole)
  const permissions = useSelector(selectPermissions)
  const variantData = useSelector(getPackageDetailVariant)
  const canEdit = permissions.wikipackVariant?.canUpdate || false

  const canCreate = canUserAddVariant(userRoles)

  const [filters, setFilters] = useState<AttachmentFilter>({})

  const dispatch = useDispatch()

  const filteredList = filterAttachmentsByPermissions({
    attachments: list,
    section: 'wikipackVariant',
    permissions: permissions,
    crud: 'onRead'
  })

  const deleteAttachHandler = (attachData: Record<string, any>) =>
    dispatch(
      packagingActions.deleteAttachment({
        attachData,
        variantId,
        packageId
      })
    )

  const updateAttachHandler = (attachData: Record<string, any>) =>
    dispatch(
      packagingActions.updateVariantAttach({
        attachData,
        variantId,
        packageId
      })
    )

  const addNewAttachVersion = (attachData: Record<string, any>) =>
    dispatch(
      packagingActions.saveNewVariantVersion({
        attachData,
        packageId,
        variantId
      })
    )

  const applyAttachFilterhandler = (newFilters: Record<string, any>) => {
    setFilters(newFilters)
  }

  const addAttachHandler = (attachData: Record<string, any>) =>
    dispatch(
      packagingActions.createVariantInMono({
        attachData,
        variantId: get(variantData, 'id', ''),
        packageId
      })
    )

  return (
    <AttachSection
      dataList={filteredList}
      tableConfig={ATTACHMENTS_IN_DETAIL_TABLE_CONFIG}
      hasDelBtn={!isOnEdit && canEdit}
      onDelete={deleteAttachHandler}
      hasDownBtn
      hasEditBtn={!isOnEdit && canEdit}
      onUpdate={updateAttachHandler}
      hasSyncBtn={!isOnEdit && canEdit}
      onSync={updateAttachHandler}
      hasAddNewVerBtn={!isOnEdit && canEdit}
      onAddNewVersion={addNewAttachVersion}
      hasFilterBtn
      filters={filters}
      onUpdateFilter={applyAttachFilterhandler}
      showEmptyOnTable
      addConfig={
        !isOnEdit &&
        canCreate && { onAddAttachment: addAttachHandler, topAddHasIcon: true }
      }
    />
  )
}

export default AttachmentInVariant
