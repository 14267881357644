import { FC, useState } from 'react'

import { useDispatch } from 'react-redux'

import { Button, Modal } from '../../../components'
import { appActions } from '../../../features/app'
import { fSchemaSliceActions } from '../../../features/formulaSchema'

import { OtherTestedPackagingModalContent } from './OtherTestedPackagingModalContent'
import { ModalBodyContainer } from './styled'
import { IOtherTestedPackForm, IOtherTestedPackagingModalProps } from './types'

export const OtherTestedPackagingModal: FC<IOtherTestedPackagingModalProps> = ({
  btnLabel,
  btnIcon = 'PlusCircleOutlined',
  modalKey,
  onSave,
  defaultDropdown = [],
  defaultValue
}) => {
  const dispatch = useDispatch()

  const [formData, setFormData] = useState<IOtherTestedPackForm>({})

  const updateHandler = (data: IOtherTestedPackForm) => {
    setFormData(data)
  }

  const closeModalHandler = () => {
    dispatch(appActions.closeModal({ modalKey }))
    dispatch(fSchemaSliceActions.setPKCodesDropdown([]))
    setFormData({})
  }
  const handleFinish = () => {
    onSave(formData)
    closeModalHandler()
  }

  const isSaveDisable =
    !formData.weightLossTest || !formData.testResult || !formData.testStartDate

  const initialHandler = () => {
    if (defaultValue) {
      const initForm: IOtherTestedPackForm = {
        materials: defaultValue.materials,
        maxPercentVolatilesInSpecific: defaultValue.maxPercentVolatilesInSpecific,
        minPercentVolatilesInSpecific: defaultValue.minPercentVolatilesInSpecific,
        pkcodesUuid: defaultValue.sideCollectorId,
        sideCollectorId: defaultValue.sideCollectorId,
        testedCode: defaultValue.testedCode,
        testNumber: defaultValue.testNumber,
        testResult: defaultValue.testResult,
        testStartDate: defaultValue.testStartDate,
        weightLossTest: defaultValue.weightLossTest,
        formulaSchemaId: defaultValue.formulaSchemaId,
        category: defaultValue.category,
        id: defaultValue.id
      }
      return setFormData(initForm)
    }
    return setFormData({})
  }

  return (
    <Modal
      isSmall={true}
      btnName={btnLabel}
      btnType="link"
      btnIcon={btnIcon}
      modalKey={modalKey}
      title=""
      footer={[
        <Button key="cancel" label="Cancel" onClick={closeModalHandler} />,
        <Button
          key="save"
          label="Save"
          disabled={isSaveDisable}
          onClick={() => handleFinish()}
        />
      ]}
      btnClicked={initialHandler}
      centered
      size="large"
    >
      <ModalBodyContainer>
        <OtherTestedPackagingModalContent
          defaultDropdown={defaultDropdown}
          data={formData}
          onUpdate={updateHandler}
        />
      </ModalBodyContainer>
    </Modal>
  )
}
