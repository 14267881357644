import { IDropdown } from '../../../../features/types'

export const TEST_RESULT_DROPDOWN: IDropdown[] = [
  { value: 'COMPATIBLE', name: 'COMPATIBLE' },
  { value: 'NOT_COMPATIBLE', name: 'NOT COMPATIBLE' },
  { value: 'NOT_EXECUTED', name: 'NOT EXECUTED' },
  { value: 'PK_NOT_APPROPRIATE', name: 'PK NOT APPROPRIATE' },
  { value: 'PK_NOT_COMPLIANT', name: 'PK NOT COMPLIANT' }
]

export const WEIGHT_LOSS_DROPDOWN: IDropdown[] = [
  { value: 'FAILED', name: 'FAILED' },
  { value: 'NO_VOLATILES', name: 'NO VOLATILES' },
  { value: 'NOT_CONDUCTED', name: 'NOT CONDUCTED' },
  { value: 'PASSED', name: 'PASSED' },
  { value: 'PK_NOT_APPROPRIATE', name: 'PK NOT APPROPRIATE' },
  { value: 'PK_HOMOLOGATED', name: 'PK HOMOLOGATED' }
]
