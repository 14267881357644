import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  canReadMasterSpecificationDetail,
  canUserAddMasterSpecification
} from '../../../auth-msal'
import { SectionTitle, Table } from '../../../components'
import Button from '../../../components/Button'
import { Divider } from '../../../components/Divider'
import { TOP_RIGHT } from '../../../components/Table/constants'
import { Text } from '../../../components/Text'
import { Tooltip } from '../../../components/Tooltip'
import { selectAppLoaderBySectionKey } from '../../../features/app/selectors'
import { masterSpecActions } from '../../../features/masterSpec/actions'
import { selectUserRole } from '../../../features/users/selectors'
import { columnsGeneratorHelper } from '../../../utils'
import { AddMasterSpecificationModal } from '../AddMasterSpecificationModal'
import { EmptyWrapper } from './styled'
import { IMasterSpecificationsListProps } from './types'

export const MasterSpecificationsList: FC<IMasterSpecificationsListProps> = ({
  dataList = [],
  tableConfig,
  packageId
}) => {
  const navigate = useNavigate()
  const distpacth = useDispatch()

  const userRoles = useSelector(selectUserRole)
  const isLoading = useSelector(selectAppLoaderBySectionKey('section-master-table'))
  const { packageVariantId } = useParams()

  const columns = columnsGeneratorHelper(tableConfig)

  const actionConfig = {
    columnTitle: 'Actions',
    actionRenderer: ({ data }: any) => (
      <>
        {canReadMasterSpecificationDetail(userRoles) && (
          <Tooltip title="Detail">
            <Button
              onClick={() =>
                navigate(
                  `/wikipack/packaging/${packageId}/master-specifications/${data.id}`
                )
              }
              iconName="InfoCircleOutlined"
              variant="link"
            />
          </Tooltip>
        )}
        <Button
          onClick={() =>
            distpacth(masterSpecActions.exportMasterSpecification({ id: data.id }))
          }
          iconName="DownloadOutlined"
          variant="text"
        />
      </>
    )
  }

  return (
    <>
      <SectionTitle
        value="Master Specifications"
        addon={
          !!dataList?.length && (
            <AddMasterSpecificationModal packageId={packageId || ''} />
          )
        }
        isBold
      />
      <Divider />
      <Table
        enablePagination
        paginationPosition={TOP_RIGHT}
        columns={columns}
        items={dataList}
        rowKey="id"
        actionColumnConfig={actionConfig}
        emptyBody={
          <EmptyWrapper>
            <Text text="There are no Master Specifications" variant="h4" />
            {canUserAddMasterSpecification(userRoles) && (
              <AddMasterSpecificationModal
                packageVariantId={packageVariantId}
                packageId={packageId || ''}
              />
            )}
          </EmptyWrapper>
        }
        isLoading={isLoading}
        forceHeight
      />
    </>
  )
}
