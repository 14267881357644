import { apiClient } from '../../api/client'

import {
  ToolingDto,
  ToolingResultSearch,
  ToolingTaxonomyDto,
  ToolingTaxonomyFilters
} from './model'

export const getToolingTaxonomies = () =>
  apiClient.get<ToolingTaxonomyDto>('toolings/taxonomies')

export const searchTooling = (filters: ToolingTaxonomyFilters) =>
  apiClient.post<ToolingResultSearch>('toolings/search', filters)

export const exportToolings = (filters: ToolingTaxonomyFilters) => {
  return apiClient.post<any>(
    'toolings/export',
    { filters: filters },
    { responseType: 'blob' }
  )
}

export const getToolingById = (id: string) => apiClient.get<ToolingDto>(`toolings/${id}`)

export const deleteToolingById = (id: string) => {
  return apiClient.delete<ToolingDto>(`toolings/${id}`)
}
export const updateToolingById = (id: string, payload: any) =>
  apiClient.patch<ToolingDto>(`toolings/${id}`, payload)

export const createTooling = (payload: any) =>
  apiClient.post<ToolingDto>('toolings', payload)
