import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { Button, SectionTitle, TooltipBtn } from '../../components'
import { Divider } from '../../components/Divider'
import { Loader } from '../../components/Loader'
import { Title } from '../../components/Title'
import { PageContent } from '../../containers'
import MasterSpecificationDetailContent from '../../containers/MasterSpecificationDetailsContent'
import { masterSpecActions } from '../../features/masterSpec/actions'
import { masterSpecSliceActions } from '../../features/masterSpec/reducer'
import {
  selectCharacteristicList,
  selectIsLoading,
  selectMasterSpecDetail,
  selectNewMasterSpec
} from '../../features/masterSpec/selectors'
import { packagingActions } from '../../features/packaging'
import {
  getPackageDetail,
  selectPackagingError
} from '../../features/packaging/selectors'
import { GenericLayout } from '../../layouts'
import { cleanMasterSpec } from '../../utils/masterAndProjSpecificationHelpers'
import { ButtonsWrapper } from './styled'
import { IMasterSpecificationDetailPropps } from './types'

const MasterSpecificationDetail: FC<IMasterSpecificationDetailPropps> = () => {
  // Selector
  const masterSpecDetails = useSelector(selectMasterSpecDetail)
  const newMasterSpec = useSelector(selectNewMasterSpec)
  const packagingError = useSelector(selectPackagingError)
  const packageDetails = useSelector(getPackageDetail)
  const isLoading = useSelector(selectIsLoading)
  const characteristicList = useSelector(selectCharacteristicList)

  const [isEditing, setIsEditing] = useState(false)
  const { specId, packageId } = useParams()
  const navigate = useNavigate()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(masterSpecActions.getDetail({ specId: specId || '' }))
    dispatch(masterSpecActions.getSpecificationSections())
    dispatch(masterSpecActions.getCharacteristics())
    dispatch(masterSpecSliceActions.clearError())
    packageId &&
      dispatch(packagingActions.getPackagingDetail({ id: packageId, hasLoader: false }))
    dispatch(masterSpecSliceActions.clearSuccess())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(masterSpecActions.getDetail({ specId: specId || '' }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing])

  const data = isEditing ? newMasterSpec : masterSpecDetails

  const editModeHandler = () => {
    setIsEditing(true)
  }
  const cancelHandler = () => {
    setIsEditing(false)
  }
  const deleteHandler = () => {
    dispatch(masterSpecActions.deleteMasterSpecification({ specId: specId || '' }))
    navigate(-1)
  }

  const saveHandler = () => {
    const payload = cleanMasterSpec(data)
    dispatch(masterSpecActions.updateMasterSpecification(payload || {}))
    setIsEditing(false)
  }

  return (
    <GenericLayout error={packagingError}>
      <PageContent
        footerHeight={footerHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        <Title title="Master Specification Details" variantTitle="h3" />

        <Divider disableBorder />
        <SectionTitle
          value={''}
          isBold
          addon={
            <ButtonsWrapper>
              {!isEditing && specId && (
                <Button
                  onClick={() =>
                    dispatch(
                      masterSpecActions.exportMasterSpecification({
                        id: specId,
                        loader: 'page'
                      })
                    )
                  }
                  label="Export"
                  iconName="DownloadOutlined"
                  variant="link"
                  iconPosition="left"
                />
              )}
              <TooltipBtn
                tooltipLabel="Delete"
                btnLabel="Delete"
                iconPosition="left"
                hasIcon
                iconName="DeleteOutlined"
                message="Do you want to delete this Master Specification?"
                onConfirm={() => {
                  deleteHandler()
                }}
                btnVariant={isEditing ? 'link' : 'ghost'}
              />
              {!isEditing ? (
                <>
                  <Button label="Edit" onClick={editModeHandler} />
                </>
              ) : (
                <>
                  <Button label="Cancel" variant="ghost" onClick={cancelHandler} />
                  <Button label="Save" onClick={saveHandler} />
                </>
              )}
            </ButtonsWrapper>
          }
        />
        {isLoading ? (
          <Loader />
        ) : (
          <MasterSpecificationDetailContent
            packageDetails={packageDetails || {}}
            masterSpecDetails={data}
            isEditing={isEditing}
            characteristicList={characteristicList}
          />
        )}
      </PageContent>
    </GenericLayout>
  )
}

export default MasterSpecificationDetail
