import { AnyAction, PayloadAction } from '@reduxjs/toolkit'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import {
  filterFormulaByStatus,
  refactorPkCodesDropdown
} from '../../utils/formulaSchemaHelpers'
import { transformNullStringToNull } from '../../utils/taxonomiesHelper'

import { selectUserEmail } from '../users/selectors'

import { fSchemaActions } from './actions'
import {
  addOtherTestedPackaging,
  addTestedClaim,
  addTestedClaimAttachment,
  deleteOtherTestedPackaging,
  deleteTestedClaim,
  getPKDropdown,
  getSchemaId,
  getTestedClaimTaxonomy,
  patchOtherTestedPackaging,
  patchTestedClaim,
  searchSchema,
  updateIndustrialCode,
  updateSchema
} from './api'
import { FORMULA_SCHEMA_STATUS_VISIBLE, fSchemaActionTypes } from './constants'
import {
  FormulaResultSearch,
  FormulaSchemaDto,
  IAddOtherTestedPackagingActionPayload,
  IEditOtherTestedPackagingActionPayload,
  IPackagingSearchResult,
  Pagination
} from './model'
import { fSchemaSliceActions } from './reducer'
import {
  getSchemaFilters,
  selectFormulaDetail,
  selectFormulaSchemaPagination,
  selectFormulaTempDetail
} from './selectors'

function* getFSchema() {
  yield put(fSchemaActions.setIsLoading(true))
  const filters: Record<string, any> = yield select(getSchemaFilters)
  const { page: selectedPage, pageSize: selectedPageSize }: Pagination = yield select(
    selectFormulaSchemaPagination
  )
  try {
    const schema: FormulaResultSearch = yield call(searchSchema, {
      ...filters,
      status: FORMULA_SCHEMA_STATUS_VISIBLE,
      page: selectedPage,
      pageSize: selectedPageSize
    })

    const { results, totalPages, page, pageSize, total } = schema

    yield put(fSchemaActions.setPagination({ totalPages, page, pageSize, total }))
    yield put(fSchemaActions.setData({ data: results }))
  } catch (err: any) {
    yield put(fSchemaActions.setError(err))
  } finally {
    yield put(fSchemaActions.setIsLoading(false))
  }
}

function* fetchDetail({ payload }: AnyAction) {
  const { id, hasLoader = true } = payload
  if (hasLoader) {
    yield put(fSchemaActions.setIsLoading(true))
  }
  try {
    const formulaSchema: FormulaSchemaDto = yield call(getSchemaId, id)
    const refactoredFormulaSchema = {
      ...formulaSchema,
      formula: filterFormulaByStatus(formulaSchema.formula)
    }
    yield put(fSchemaActions.setDetail(refactoredFormulaSchema))
  } catch (err: any) {
    yield put(fSchemaActions.setError(err))
  } finally {
    if (hasLoader) {
      yield put(fSchemaActions.setIsLoading(false))
    }
  }
}

function* clearFilters() {
  yield put(fSchemaActions.clearFilters())
}

function* filterToTemp() {
  yield put(fSchemaActions.filterToTemp())
}
function* tempToFilter() {
  yield put(fSchemaActions.tempToFilter())
}
function* prepareForUpdate() {
  yield put(fSchemaActions.setTempDetail())
}

function* updateFSchema() {
  yield put(fSchemaActions.setIsLoading(true))
  try {
    const author: string = yield select(selectUserEmail)
    const formulaData: Record<string, any> = yield select(selectFormulaTempDetail)
    const { id: fSchemaId } = formulaData
    const newFormulaData = transformNullStringToNull(formulaData)
    yield call(updateSchema, { ...newFormulaData, author })
    yield put(fSchemaActions.getFSchemaDetail({ id: fSchemaId, hasLoader: false }))
  } catch (err: any) {
    yield put(fSchemaActions.setError(err))
  } finally {
    yield put(fSchemaActions.setIsLoading(false))
  }
}

function* saveIndustrialCode({ payload }: Record<string, any>) {
  const author: string = yield select(selectUserEmail)
  try {
    yield call(updateIndustrialCode, { ...payload, author })
  } catch (error) {
    console.log(error)
  }
}

function* saveUpdatedFormula({ payload }: AnyAction) {
  const { data: updatedFormula } = payload
  const author: string = yield select(selectUserEmail)
  const { id: fSchemaId } = yield select(selectFormulaDetail)
  yield put(fSchemaActions.setIsLoading(true))
  try {
    const newFormulaObj = transformNullStringToNull(updatedFormula)
    yield call(updateIndustrialCode, { ...newFormulaObj, author })
    yield put(fSchemaActions.getFSchemaDetail({ id: fSchemaId, hasLoader: false }))
  } catch (err: any) {
    yield put(fSchemaActions.setError(err))
  } finally {
    yield put(fSchemaActions.setIsLoading(false))
  }
}

function* addOtherTestedPackagingSaga({
  payload
}: PayloadAction<IAddOtherTestedPackagingActionPayload>) {
  const { data } = payload
  const { id: fSchemaId } = yield select(selectFormulaDetail)
  yield put(fSchemaActions.setIsLoading(true))
  try {
    yield call(addOtherTestedPackaging, { ...data, formulaSchemaId: fSchemaId })
    yield put(fSchemaActions.getFSchemaDetail({ id: fSchemaId, hasLoader: false }))
  } catch (err: any) {
    yield put(fSchemaActions.setError(err))
  } finally {
    yield put(fSchemaActions.setIsLoading(false))
  }
}

function* addTestedClaimSaga({ payload }: AnyAction) {
  const { data, attachment } = payload
  const { id: fSchemaId } = yield select(selectFormulaDetail)
  yield put(fSchemaActions.setIsLoading(true))
  try {
    const resp: Record<string, any> = yield call(addTestedClaim, {
      ...data,
      formulaSchemaId: fSchemaId
    })
    const form_data = new FormData()
    if (attachment) {
      form_data.append('file', attachment.file)
    }
    yield call(addTestedClaimAttachment, form_data, resp.id)
    yield put(fSchemaActions.getFSchemaDetail({ id: fSchemaId, hasLoader: false }))
  } catch (err: any) {
    yield put(fSchemaActions.setError(err))
  } finally {
    yield put(fSchemaActions.setIsLoading(false))
  }
}

function* patchOtherTestedPackagingSaga({
  payload
}: PayloadAction<IEditOtherTestedPackagingActionPayload>) {
  const author: string = yield select(selectUserEmail)
  const { data } = payload
  const { id: fSchemaId } = yield select(selectFormulaDetail)
  yield put(fSchemaActions.setIsLoading(true))
  try {
    yield call(patchOtherTestedPackaging, {
      ...data,
      formulaSchemaId: fSchemaId,
      author
    })
    yield put(fSchemaActions.getFSchemaDetail({ id: fSchemaId, hasLoader: false }))
  } catch (err: any) {
    yield put(fSchemaActions.setError(err))
  } finally {
    yield put(fSchemaActions.setIsLoading(false))
  }
}

function* patchTestedClaimSaga({ payload }: AnyAction) {
  const author: string = yield select(selectUserEmail)
  const { data } = payload
  const { id: fSchemaId } = yield select(selectFormulaDetail)
  yield put(fSchemaActions.setIsLoading(true))
  try {
    yield call(patchTestedClaim, {
      ...data,
      formulaSchemaId: fSchemaId,
      author: author
    })
    yield put(fSchemaActions.getFSchemaDetail({ id: fSchemaId, hasLoader: false }))
  } catch (err: any) {
    yield put(fSchemaActions.setError(err))
  } finally {
    yield put(fSchemaActions.setIsLoading(false))
  }
}

function* deleteOtherTestedPackagingSaga({ payload }: AnyAction) {
  const { id: fSchemaId } = yield select(selectFormulaDetail)

  yield put(fSchemaActions.setIsLoading(true))
  try {
    yield call(deleteOtherTestedPackaging, payload)
    yield put(fSchemaActions.getFSchemaDetail({ id: fSchemaId, hasLoader: false }))
  } catch (err: any) {
    yield put(fSchemaActions.setError(err))
  } finally {
    yield put(fSchemaActions.setIsLoading(false))
  }
}

function* deleteTestedClaimSaga({ payload }: AnyAction) {
  const { id: fSchemaId } = yield select(selectFormulaDetail)

  yield put(fSchemaActions.setIsLoading(true))
  try {
    yield call(deleteTestedClaim, payload)
    yield put(fSchemaActions.getFSchemaDetail({ id: fSchemaId, hasLoader: false }))
  } catch (err: any) {
    yield put(fSchemaActions.setError(err))
  } finally {
    yield put(fSchemaActions.setIsLoading(false))
  }
}

function* getPKDropdownSaga({ payload }: AnyAction) {
  const { pkCode } = payload
  try {
    const dropdownItems: IPackagingSearchResult[] = yield call(getPKDropdown, pkCode)
    const refactoredDropdown = refactorPkCodesDropdown(dropdownItems)
    yield put(fSchemaSliceActions.setPKCodesDropdown(refactoredDropdown))
  } catch (err: any) {
    yield put(fSchemaActions.setError(err))
  } finally {
    yield put(fSchemaActions.setIsLoading(false))
  }
}

function* getTestedClaimTaxonomySaga() {
  try {
    const dropdownItems: Record<string, any> = yield call(getTestedClaimTaxonomy)
    yield put(fSchemaActions.setTestedClaimTaxonomy(dropdownItems))
  } catch (err: any) {
    yield put(fSchemaActions.setError(err))
  } finally {
    yield put(fSchemaActions.setIsLoading(false))
  }
}

export default function* formulaSchemesSaga() {
  yield takeLatest(fSchemaActionTypes.FORMULA_SCHEMA_GET_FORMULA, getFSchema)
  yield takeLatest(fSchemaActionTypes.FORMULA_SCHEMA_GET_DETAIL, fetchDetail)
  yield takeLatest('formulaschema/clearInitialFilters', clearFilters)
  yield takeLatest('formulaSchemaSaga/filterToTemp', filterToTemp)
  yield takeLatest('formulaSchemaSaga/tempToFilter', tempToFilter)
  yield takeLatest('formulaSchemaSaga/prepareForUpdate', prepareForUpdate)
  yield takeLatest(fSchemaActionTypes.FORMULA_SCHEMA_UPDATE, updateFSchema)
  yield takeLatest('formulaSchemaSaga/saveIndustrialCode', saveIndustrialCode)
  yield takeLatest(fSchemaActionTypes.FSCHEMA_CONFIRM_UPDATE_FORMULA, saveUpdatedFormula)
  yield takeLatest(
    fSchemaActionTypes.ADD_OTHER_TESTED_PACKAGING,
    addOtherTestedPackagingSaga
  )
  yield takeLatest(
    fSchemaActionTypes.PATCH_OTHER_TESTED_PACKAGING,
    patchOtherTestedPackagingSaga
  )
  yield takeLatest(
    fSchemaActionTypes.DELETE_OTHER_TESTED_PACKAGING,
    deleteOtherTestedPackagingSaga
  )
  yield takeLatest(fSchemaActionTypes.ADD_TESTED_CLAIM, addTestedClaimSaga)
  yield takeLatest(fSchemaActionTypes.PATCH_TESTED_CLAIM, patchTestedClaimSaga)
  yield takeLatest(fSchemaActionTypes.DELETE_TESTED_CLAIM, deleteTestedClaimSaga)
  yield takeLatest(fSchemaActionTypes.GET_PK_DROPDOWN, getPKDropdownSaga)
  yield takeLatest(
    fSchemaActionTypes.GET_TESTED_CLAIM_TAXONOMY,
    getTestedClaimTaxonomySaga
  )
}
