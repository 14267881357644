import { get, isEqual } from 'lodash'
import moment, { Moment } from 'moment'
import {
  STATUS_WARN,
  WarningsJSON
} from '../../containers/Product/ResultSection/ProductsTable/types'
import { IProductItem } from '../../features/product/model'
import { IOtherTestedPackaging, ProductDto } from '../../features/products/model'
import { IPermissions } from '../../features/users/model'
import {
  OTHER_TESTED_PACKAGING_COLUMNS,
  OTHER_TESTED_PACKAGING_COLUMNS_IN_PRODUCT
} from '../../pages/FSchemaDetail/constants'
import {
  TEST_RESULT_DROPDOWN,
  WEIGHT_LOSS_DROPDOWN
} from '../../pages/FSchemaDetail/OtherTestedPackagingModal/OtherTestedPackagingModalContent/constants'
import { getDropdownItemByValue } from '../dropdownHelpers'
import { filterFormulaByStatus } from '../formulaSchemaHelpers'
import { rowsGeneratorHelper_new } from '../tableHelpers'
import {
  IRefactoredOtherTestedPackaging,
  TGetUniqueFormulaSchemaCodeListFunc,
  TRefactorOtherTestedColumnTableConfigFunc,
  TRefactorProductDetailFunc,
  TRefactorProductForOtherTestedPackSectionFunc,
  TRefactorProductOtherTestedPackagingFunc
} from './types'

const generateFormulaFSSchemaLinkMessage = (
  warningsJSON: WarningsJSON[],
  prefix: string
) => {
  const [filtered] = warningsJSON.filter(
    (w: WarningsJSON) => w.type === 'FORMULA_FS_LINK'
  )
  if (filtered) {
    return `${prefix} BU as formula ${filtered.formulaCodeDEVEX} has been associated to new FS ${filtered.newFormulaSchemaCode}`
  }
  return `${prefix} BU as Formula-Formula Schema association changed`
}

export const warningMessageGenerator: (w: string[], wJSON: WarningsJSON[]) => string = (
  warnings = [],
  warningsJSON = []
) => {
  let message = ''
  if (warnings.includes(STATUS_WARN.PACKAGE)) {
    message = 'Check with PK Team as PK Status changed'
  }
  if (warnings.includes(STATUS_WARN.FSCHEMA)) {
    message = 'Check with BU as FS Status changed'
  }
  if (warnings.includes(STATUS_WARN.FORMULA)) {
    message = 'Check with BU as Formula status changed'
  }
  if (warnings.includes(STATUS_WARN.FORMULA_FSCHEMA_LINK)) {
    message = generateFormulaFSSchemaLinkMessage(warningsJSON, 'Check with')
  }
  if (warnings.includes(STATUS_WARN.PACKAGE) && warnings.includes(STATUS_WARN.FSCHEMA)) {
    message = 'Check with PK Team and BU as PK Status and FS Status changed'
  }
  if (warnings.includes(STATUS_WARN.PACKAGE) && warnings.includes(STATUS_WARN.FORMULA)) {
    message = 'Check with PK Team and BU as PK Status and Formula Status changed'
  }
  if (warnings.includes(STATUS_WARN.FSCHEMA) && warnings.includes(STATUS_WARN.FORMULA)) {
    message = 'Check with BU as FS Status and Formula Status changed'
  }
  if (warnings.includes(STATUS_WARN.TOOLING)) {
    message = 'Check with Tooling Team as Tooling Status changed'
  }
  if (warnings.includes(STATUS_WARN.TOOLING) && warnings.includes(STATUS_WARN.PACKAGE)) {
    message = 'Check with Tooling and PK Team as Tooling Status ad PK Status changed'
  }
  if (warnings.includes(STATUS_WARN.TOOLING) && warnings.includes(STATUS_WARN.FSCHEMA)) {
    message = 'Check with Tooling and BU as Tooling Status and FS Status changed'
  }
  if (
    warnings.includes(STATUS_WARN.TOOLING) &&
    warnings.includes(STATUS_WARN.FSCHEMA) &&
    warnings.includes(STATUS_WARN.PACKAGE)
  ) {
    message =
      'Check with Tooling, BU and PK Team as Tooling Status, FS Status and PK Status changed'
  }

  if (
    warnings.includes(STATUS_WARN.PACKAGE) &&
    warnings.includes(STATUS_WARN.FSCHEMA) &&
    warnings.includes(STATUS_WARN.FORMULA)
  ) {
    message =
      'Check with PK Team and BU as PK Status, FS Status and Formula Status changed'
  }

  if (
    warnings.includes(STATUS_WARN.PACKAGE) &&
    warnings.includes(STATUS_WARN.FORMULA_FSCHEMA_LINK)
  ) {
    message =
      generateFormulaFSSchemaLinkMessage(warningsJSON, 'Check with PK Team and') +
      ' and PK Status changed'
  }

  if (
    warnings.includes(STATUS_WARN.TOOLING) &&
    warnings.includes(STATUS_WARN.FORMULA_FSCHEMA_LINK)
  ) {
    message =
      generateFormulaFSSchemaLinkMessage(warningsJSON, 'Check with Tooling Team and') +
      ' and Tooling Status changed'
  }

  if (
    warnings.includes(STATUS_WARN.FORMULA) &&
    warnings.includes(STATUS_WARN.FORMULA_FSCHEMA_LINK)
  ) {
    message =
      generateFormulaFSSchemaLinkMessage(warningsJSON, 'Check with') +
      ' and Formula Status changed'
  }

  return message
}

export const productFSMonoRefactor: (
  fSchema: Record<string, any>
) => Record<string, any> = (fSchemaData) => {
  return {
    ...fSchemaData,
    formulaCodeDEVEX: cleanAndFlat(get(fSchemaData, 'formula', []), 'formulaCodeDEVEX'),
    formulaCodeSAP: cleanAndFlat(get(fSchemaData, 'formula', []), 'formulaCodeSAP'),
    formulaName: cleanAndFlat(get(fSchemaData, 'formula', []), 'formulaName'),
    icShelfLife: cleanAndFlat(get(fSchemaData, 'formula', []), 'icShelfLife'),
    icPao: cleanAndFlat(get(fSchemaData, 'formula', []), 'icPao'),
    color: cleanAndFlat(get(fSchemaData, 'formula', []), 'color')
  }
}

export const cleanAndFlat: (
  list: Record<string, any>[],
  key: string
) => Record<string, any>[] = (list, key) => {
  return list
    .filter((data) => !!data[key])
    .map((data) => data[key])
    .flat()
}

// When the value is not found using the first key the function will look for it using the second key
export const cleanAndFlatWithBackupKey: (
  list: Record<string, any>[],
  key1: string,
  key2: string
) => Record<string, any>[] = (list, key1, key2) => {
  return list
    .filter((data) => data[key1] || data[key2])
    .map((data) => data[key1] || data[key2])
    .flat()
}

export const booleanStrictEvalution: (
  list: Record<string, any>[],
  key: string
) => boolean | null = (list, key) => {
  const selectedList = list.map((data) => data[key])
  const hasNull = selectedList.some((data: any) => data === null)
  if (hasNull) return null
  return selectedList.every((data) => !!data)
}

export const getSideRegulatoryDates = (side: any) => {
  return [
    side?.formulaSchema?.regulatoryLastUpdate,
    ...side?.formulaSchema?.formula.map((formula: any) => formula.regulatoryLastUpdate)
  ]
}
const productStatusMessagesMap: Record<string, string> = {
  true_true: '',
  true_false:
    'The Status Ready To Go cannot be saved because the regulatory check is missing',
  false_true:
    "The Status Ready To Go cannot be saved because there isn't any Industrial Code selected",
  false_false:
    "The Status Ready To Go cannot be saved because the regulatory check is missing and there isn't any Industrial Code selected"
}

export const getProductStatusMessage = (
  hasIndustrialCode: boolean,
  hasLastDate: boolean
): string => {
  const messageKey = `${hasIndustrialCode}_${hasLastDate}`
  return productStatusMessagesMap[messageKey]
}

export const getOlderDate = (dates: string[]) => {
  // null date is considered the older one
  const hasNullDates = dates.some((date: any) => date === null)
  if (hasNullDates) return null
  const unixDates = dates.map((date) => new Date(date).getTime())
  return new Date(Math.min(...unixDates)).toISOString()
}

export const getRecentDate = (dates: string[]) => {
  // null date is considered the older one
  const hasNullDates = dates.some((date: any) => date === null)
  if (hasNullDates) return null
  const unixDates = dates.map((date) => new Date(date).getTime())
  return new Date(Math.max(...unixDates)).toISOString()
}

export const dateStrictEvalution: (
  list: Record<string, any>[],
  key: string
) => Moment | null = (list, key) => {
  const hasNullDate = list.map((data) => data[key]).some((data: any) => data === null)
  // date can be null, FE will show '-'
  if (hasNullDate) return null
  const selectedList = list.map((data) => moment(data[key]))
  return moment.min(selectedList)
}

export const productRegulatoryGenData: (
  fSchemaList: Record<string, any>[]
) => Record<string, any> = (fSchemaList) => {
  return {
    updatedAt: dateStrictEvalution(fSchemaList, 'updatedAt'),
    regulatoryLastUpdate: dateStrictEvalution(fSchemaList, 'regulatoryLastUpdate'),
    EUCompliance: booleanStrictEvalution(fSchemaList, 'EUCompliance'),
    USCompliance: booleanStrictEvalution(fSchemaList, 'USCompliance'),
    chinaCompliance: booleanStrictEvalution(fSchemaList, 'chinaCompliance'),
    JPCompliance: booleanStrictEvalution(fSchemaList, 'JPCompliance'),
    koreaCompliance: booleanStrictEvalution(fSchemaList, 'koreaCompliance'),
    indiaCompliance: booleanStrictEvalution(fSchemaList, 'indiaCompliance'),
    brazilCompliance: booleanStrictEvalution(fSchemaList, 'brazilCompliance'),
    cleanAtSephora: booleanStrictEvalution(fSchemaList, 'cleanAtSephora'),
    cleanAtUlta: booleanStrictEvalution(fSchemaList, 'cleanAtUlta'),
    intercosCleanBL: booleanStrictEvalution(fSchemaList, 'intercosCleanBL'),
    naturalPercentage: cleanAndFlat(fSchemaList, 'naturalPercentage'),
    SPFDetails: cleanAndFlat(fSchemaList, 'SPFDetails'),
    vegan: booleanStrictEvalution(cleanAndFlat(fSchemaList, 'formula'), 'vegan'),
    veganNotes: cleanAndFlat(cleanAndFlat(fSchemaList, 'formula'), 'veganNotes'),
    RSPO: booleanStrictEvalution(cleanAndFlat(fSchemaList, 'formula'), 'RSPO'),
    microplasticFree: booleanStrictEvalution(
      cleanAndFlat(fSchemaList, 'formula'),
      'microplasticFree'
    ),
    microplasticFreeNotes: cleanAndFlat(
      cleanAndFlat(fSchemaList, 'formula'),
      'microplasticFreeNotes'
    ),
    siliconesFree: booleanStrictEvalution(
      cleanAndFlat(fSchemaList, 'formula'),
      'siliconesFree'
    ),
    talcFree: booleanStrictEvalution(cleanAndFlat(fSchemaList, 'formula'), 'talcFree'),
    pegFree: booleanStrictEvalution(cleanAndFlat(fSchemaList, 'formula'), 'pegFree'),
    d5Free: booleanStrictEvalution(cleanAndFlat(fSchemaList, 'formula'), 'd5Free'),
    silicaFree: booleanStrictEvalution(
      cleanAndFlat(fSchemaList, 'formula'),
      'silicaFree'
    ),
    GMO: cleanAndFlat(cleanAndFlat(fSchemaList, 'formula'), 'GMO'),
    paraben: booleanStrictEvalution(cleanAndFlat(fSchemaList, 'formula'), 'paraben'),
    cleanOfPreservatives: booleanStrictEvalution(fSchemaList, 'cleanOfPreservatives'),
    cleanOfMineralOils: booleanStrictEvalution(fSchemaList, 'cleanOfMineralOils'),
    regulatoryNotes: cleanAndFlat(fSchemaList, 'regulatoryNotes'),
    fragrance: booleanStrictEvalution(fSchemaList, 'fragrance'),
    glutenFree: booleanStrictEvalution(fSchemaList, 'glutenFree')
  }
}

export const productRefactorDataForUpdate = (data: ProductDto, author: string) => {
  const refactoredSides = get(data, 'productSides', []).map((sideData: any) => {
    const selectedFormula = get(sideData, 'formulaSelected', []).map(({ id }: any) => id)

    const result = {
      id: get(sideData, 'id', ''),
      marketBench: get(sideData, 'marketBench', ''),
      externalClaim: get(sideData, 'externalClaim', ''),
      note: get(sideData, 'note', ''),
      intercosInside: get(sideData, 'intercosInside', ''),
      opaquePackaging: get(sideData, 'opaquePackaging', ''),
      firstProductionOrigin: get(sideData, 'firstProductionOrigin', ''),
      applicationArea: get(sideData, 'applicationArea', ''),
      productCategory: get(sideData, 'productCategory', ''),
      productForm: get(sideData, 'productForm', ''),
      fillingMethodCycle: get(sideData, 'fillingMethodCycle', ''),
      internalLastingPosition: get(sideData, 'internalLastingPosition', ''),
      formulaTransferTo: get(sideData, 'formulaTransferTo', ''),
      intercosInsidePositioning: get(sideData, 'intercosInsidePositioning', ''),
      mascaraPositioning: get(sideData, 'mascaraPositioning', ''),
      stabilityStartDate: get(sideData, 'stabilityStartDate'),
      innovationLevel: get(data, 'innovationLevel') || get(sideData, 'innovationLevel'),
      bulkLevel: get(sideData, 'bulkLevel'),
      industrialLevel: get(sideData, 'industrialLevel'),
      packagingLevel: get(sideData, 'packagingLevel'),
      compatibility: get(sideData, 'compatibility'),
      pdLaunchYear: get(data, 'pdLaunchYear') || get(sideData, 'pdLaunchYear'),
      pdCollection: get(data, 'pdCollection') || get(sideData, 'pdCollection'),
      selectedFormula
    }
    return result
  })

  const productCharacteristics = get(data, 'characteristics', {})

  return {
    author,
    productSides: refactoredSides,
    name: get(data, 'name', ''),
    sapOfferCode: get(data, 'sapOfferCode', ''),
    status: get(data, 'status', ''),
    fillingAvailableIn: get(data, 'fillingAvailableIn', ''),
    businessSegment: get(data, 'businessSegment', ''),
    businessUnit: get(data, 'businessUnit', ''),
    ...productCharacteristics
  }
}

export const productRefactorDataForSave: (data: IProductItem, author: string) => any = (
  data,
  author
) => {
  const sides = data.sides.map(
    ({
      fSchema,
      packageVariantId,
      formulaTransferTo,
      intercosInside,
      opaquePackaging,
      productForm,
      productCategory,
      applicationArea,
      marketBench,
      externalClaim,
      note,
      firstProductionOrigin,
      fillingMethodCycle,
      internalLastingPosition,
      intercosInsidePositioning,
      mascaraPositioning,
      stabilityStartDate,
      innovationLevel,
      bulkLevel,
      industrialLevel,
      packagingLevel,
      pdLaunchYear,
      pdCollection,
      compatibility
    }) => {
      const result = {
        formulaSchemaId: fSchema?.id || '',
        packageVariantId,
        selectedFormula: fSchema?.formula.map(({ id }) => id) || [],
        intercosInside,
        opaquePackaging,
        applicationArea,
        productCategory,
        productForm,
        formulaTransferTo,
        marketBench,
        externalClaim,
        note,
        firstProductionOrigin,
        fillingMethodCycle,
        internalLastingPosition,
        intercosInsidePositioning,
        mascaraPositioning,
        stabilityStartDate,
        innovationLevel,
        bulkLevel,
        industrialLevel,
        packagingLevel,
        pdCollection: pdCollection ? pdCollection : data.pdCollection,
        pdLaunchYear: pdLaunchYear ? pdLaunchYear : data.pdLaunchYear,
        compatibility
      }
      return result
    }
  )

  return {
    sides,
    status: 'WIP_INFORMATION',
    name: get(data, 'name', ''),
    sapOfferCode: get(data, '', ''),
    fillingAvailableIn: get(data, 'fillingAvailableIn', ''),
    businessSegment: get(data, 'businessSegment', ''),
    businessUnit: get(data, 'businessUnit', ''),
    author
  }
}

export const filterProdListByStatus: (
  list: ProductDto[],
  permissions: IPermissions
) => ProductDto[] = (list, permissions) => {
  const statusPermissions = permissions.vaultProduct?.canSeeValue.productStatus || []
  return list.filter(({ status }) => {
    const statusRules = statusPermissions.find(({ value }) => value === status)
    return statusRules ? statusRules.onRead : false
  })
}

export const concatenateIfArray = (value: string | string[]) => {
  if (Array.isArray(value)) {
    return value.join(';')
  }
  return value
}

const isListEqual = (listA: string[], listB: string[]) => {
  if (listA.length !== listB.length) return false

  listA.sort()
  listB.sort()

  return isEqual(listA, listB)
}

export const checkIfSameBussinessSegmentAndCategory = (list: Record<string, any>[]) => {
  const hasSameBussinessSegment = list.every(
    (item) => item.businessSegment === list[0].businessSegment
  )

  const hasSameCategory = list.every((prod) =>
    isListEqual(prod.productCategory, list[0].productCategory)
  )

  return hasSameBussinessSegment && hasSameCategory
}

export const refactorProductDetail: TRefactorProductDetailFunc = ({ data }) => {
  const productImage = {
    ...get(data, 'thumbnail', {}),
    value: get(data, 'thumbnail.url', '')
  }
  const marketingProfile = {
    ...get(data, 'attachments[0]', {}),
    value: get(data, 'attachments[0].url', '')
  }

  const refactoredProductSides = get(data, 'productSides', []).map((side: any) => ({
    ...side,
    formulaSchema: {
      ...side.formulaSchema,
      formula: filterFormulaByStatus(side.formulas)
    }
  }))

  const industrialCodeOfFirstSide = get(data, 'productSides[0].industrialCode', '')
  const industrialCodeFinishOfFirstSide = get(
    data,
    'productSides[0].industrialCodeFinish',
    ''
  )

  const refactoredColors = refactoredProductSides
    .flatMap((ps: any) =>
      ps.formulaSchema.formula.map((f: any) => f.color).filter(Boolean)
    )
    .join(', ')

  const refactoredData = {
    ...data,
    image: productImage,
    marketingProfile: marketingProfile,
    productSides: refactoredProductSides,
    industrialCode: industrialCodeOfFirstSide,
    industrialCodeFinish: industrialCodeFinishOfFirstSide,
    colors: refactoredColors
  }

  return refactoredData
}

export const getUniqueFormulaSchemaCodeList: TGetUniqueFormulaSchemaCodeListFunc = ({
  data
}) => {
  const productSides = get(data, 'productSides', [])
  const formulaSchemaCodeList: string[] = productSides.map(
    ({ formulaSchemaCode }: { formulaSchemaCode: string }) => formulaSchemaCode
  )
  const uniqueFSchemaCodeList = [...new Set(formulaSchemaCodeList)]
  return uniqueFSchemaCodeList
}

export const refactorProductOtherTestedPackagingRows: TRefactorProductOtherTestedPackagingFunc =
  ({ dataList }) => {
    const rowList = rowsGeneratorHelper_new<IOtherTestedPackaging>({
      dataList,
      tableConfig: OTHER_TESTED_PACKAGING_COLUMNS
    })
    return rowList.map((data: any) => {
      const testResultValue = get(data, 'testResult', '')
      const testResultLabel =
        getDropdownItemByValue({
          dropdownList: TEST_RESULT_DROPDOWN,
          value: testResultValue
        })?.name || ''

      const weightLossTestValue = get(data, 'weightLossTest', '')
      const weightLossTestLabel =
        getDropdownItemByValue({
          dropdownList: WEIGHT_LOSS_DROPDOWN,
          value: weightLossTestValue
        })?.name || ''
      return {
        ...data,
        testResultInView: testResultLabel,
        weightLossTestInView: weightLossTestLabel
      } as IRefactoredOtherTestedPackaging
    })
  }

export const refactorProductForOtherTestedPackSection: TRefactorProductForOtherTestedPackSectionFunc =
  ({ dataList = [] }) => {
    return dataList.map((data) => ({
      ...data,
      packagingCode: get(data, 'productSides[0].packagingCode', ''),
      category: get(data, 'productSides', []).map((data: any) =>
        get(data, 'packageVariant.packageFamily.category', '')
      ),
      pdLaunchYear: get(data, 'productSides', []).map(
        (data: any) => get(data, 'pdLaunchYear', '') || get(data, 'launchYear', '')
      ),
      pdCollection: get(data, 'productSides', []).map(
        (data: any) => get(data, 'pdCollection', '') || get(data, 'collection', '')
      )
    }))
  }

export const refactorOtherTestedColumnTableConfig: TRefactorOtherTestedColumnTableConfigFunc =
  ({
    pacakageCategoryRowRenderer,
    productCollectionRowRenderer,
    productCodeRowRenderer,
    packagingCodeRowRenderer,
    launchYearRowRenderer
  }) => {
    return {
      ...OTHER_TESTED_PACKAGING_COLUMNS_IN_PRODUCT,
      packagingCode: {
        ...OTHER_TESTED_PACKAGING_COLUMNS_IN_PRODUCT.packagingCode,
        renderNode: packagingCodeRowRenderer
      },
      category: {
        ...OTHER_TESTED_PACKAGING_COLUMNS_IN_PRODUCT.category,
        renderNode: pacakageCategoryRowRenderer
      },
      productCode: {
        ...OTHER_TESTED_PACKAGING_COLUMNS_IN_PRODUCT.productCode,
        renderNode: productCodeRowRenderer
      },
      pdLaunchYear: {
        ...OTHER_TESTED_PACKAGING_COLUMNS_IN_PRODUCT.pdLaunchYear,
        renderNode: launchYearRowRenderer
      },
      pdCollection: {
        ...OTHER_TESTED_PACKAGING_COLUMNS_IN_PRODUCT.pdCollection,
        renderNode: productCollectionRowRenderer
      }
    }
  }
