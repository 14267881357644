import { InfoCircleOutlined } from '@ant-design/icons'
import { Form, DatePicker as DatePickerAnt } from 'antd'
import moment, { Moment } from 'moment'
import { FC } from 'react'
import { inputMinWidth } from '../../../assets/mixins'
import { RDays, IDatePickerProps } from './types'
import { Text } from '../../Text'

export const DatePicker: FC<IDatePickerProps> = ({
  onChange,
  size = 'middle',
  value,
  label,
  placeholder,
  required = false,
  error = false,
  disabled,
  onBlur,
  enableTooltip = false,
  message,
  minDays = 0,
  excludeDays = [],
  className,
  isOpen,
  maxDate,
  minDate,
  format = 'DD-MM-YYYY',
  errorMessage
}) => {
  const hasError = error && errorMessage

  const calculateExcludeDates = (calendarDate: Moment) => {
    let days = minDays
    let currentDate = moment()
    while (days > 0) {
      currentDate.add(1, 'days')
      const day = currentDate.day()
      const dayToExlude = RDays[day]
      const isToExclude = (excludeDays as string[]).includes(dayToExlude)
      if (!isToExclude) {
        days--
      }
    }
    const calendarDayNum = calendarDate.day()
    const calendarDayString = RDays[calendarDayNum]
    const isToExclude = (excludeDays as string[]).includes(calendarDayString)
    return currentDate > calendarDate || isToExclude
  }

  const dateValidator = (calendarDate: moment.Moment) => {
    if (excludeDays.length) {
      return calculateExcludeDates(calendarDate)
    }
    if (minDays !== undefined) {
      return moment().add(minDays, 'days') >= calendarDate
    }
    if (maxDate !== undefined && maxDate !== null) {
      return calendarDate > maxDate
    }
    if (minDate !== undefined && minDate !== null) {
      return calendarDate < minDate
    }
    return false
  }

  return (
    <Form.Item
      label={label}
      required={required}
      validateStatus={error ? 'error' : undefined}
      tooltip={enableTooltip && { title: message, icon: <InfoCircleOutlined /> }}
    >
      <DatePickerAnt
        className={className}
        style={{ minWidth: inputMinWidth, width: '100%' }}
        disabled={disabled}
        size={size}
        showToday={false}
        disabledDate={dateValidator}
        format={format}
        value={value}
        onBlur={onBlur}
        onChange={(date) => onChange(date)}
        placeholder={placeholder}
        open={isOpen}
      />
      {hasError && <Text text={errorMessage} color="red" />}
    </Form.Item>
  )
}
