import { msalInstance, loginRequest, graphConfig } from '../../configs/auth'

export async function callMsGraph(accessToken?: string) {
  if (!accessToken) {
    const account = msalInstance.getActiveAccount()
    if (!account) {
      throw Error(
        'No active account! Verify a user has been signed in and setActiveAccount has been called.'
      )
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account
    })
    accessToken = response.accessToken
  }

  let headers = new Headers()
  const bearer = `Bearer ${accessToken}`

  headers.append('Authorization', bearer)

  const optionsMe = {
    method: 'GET',
    headers: headers
  }

  headers = new Headers()
  headers.append('Authorization', bearer)
  headers.append('ConsistencyLevel', 'eventual')

  const optionGroups = {
    method: 'GET',
    headers: headers
  }
  return Promise.all([
    fetch(graphConfig.graphMeEndpoint, optionsMe).then((response) => response.json()),
    fetch(graphConfig.graphMeMemberOfEndpoint, optionGroups).then((response) =>
      response.json()
    )
  ])
}
