import { apiClient } from '../../api/client'

import { IGetStoreProdDynamicFiltersPayload, IStoreProd, OrderableItem } from './model'

export const searchStoreProducts = (filters: Record<string, any>) => {
  return apiClient.post<OrderableItem[]>('orderableItem/search', filters)
}

export const getStoreProductById = (id: string) => {
  return apiClient.get<IStoreProd>(`orderableItem/${id}`)
}

export const getStoreProdChildById = (id: string) => {
  // NOTE - at the moment parent and child get are similar
  return apiClient.get<IStoreProd>(`orderableItem/${id}`)
}

export const deleteStoreProductById = (id: string) => {
  return apiClient.delete(`orderableItem/${id}`)
}

export const deleteChildById = (id: string) => {
  // NOTE - at the moment parent and child delete are similar
  return apiClient.delete(`orderableItem/${id}`)
}

export const createStoreProductApi = (data: Record<string, any>) => {
  const { form_data } = data
  return apiClient.post<any>('orderableItem', form_data)
}

export const updateStoreProductById = ({
  id,
  form_data
}: {
  id: string
  form_data: FormData
}) => {
  return apiClient.patch(`orderableItem/${id}`, form_data)
}

export const updateChildById = ({
  id,
  form_data
}: {
  id: string
  form_data: FormData
}) => {
  // NOTE - at the moment parent and child update are similar
  return apiClient.patch(`orderableItem/${id}`, form_data)
}

export const addStoreProdChild = ({
  parentId,
  form_data
}: {
  parentId: string
  form_data: FormData
}) => {
  return apiClient.post(`orderableItem/${parentId}/child`, form_data)
}

export const getStoreProductDynamicTaxonomies = (
  data?: IGetStoreProdDynamicFiltersPayload
) => {
  return apiClient.post<Record<string, any>>('v2/orderableItem/taxonomies', data)
}
