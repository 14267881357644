import { TGetDropdownItemByLabelFunc, TGetDropdownItemByValueFunc } from './types'

export const getDropdownItemByValue: TGetDropdownItemByValueFunc = ({
  dropdownList,
  value: itemValue
}) => {
  if (!itemValue) return null
  const item = dropdownList.find(({ value }) => value === itemValue)
  return item || null
}

export const getDropdownItemByLabel: TGetDropdownItemByLabelFunc = ({
  dropdownList,
  label: itemLabel
}) => {
  if (!itemLabel) return null
  const item = dropdownList.find(({ name }) => name === itemLabel)
  return item || null
}
