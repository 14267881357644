import styled from 'styled-components'

export const DatePickerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .ant-picker {
    width: 100%;
  }
`

export const InputWrapper = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  .ant-picker {
    width: 100%;
  }
`

export const RadioInputWrapper = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 4px;
  .ant-radio-group {
    width: 100%;
  }
`
