import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { set, keyBy, unset } from 'lodash'
import { IError } from '../../containers/Error/types'
import { getCleanError } from '../../utils'
import { Formula, FormulaCreateDto, FormulaSchemaDto, FormulaSchemeState } from './model'

const initialState: FormulaSchemeState = {
  items: [],
  itemsByIds: {},
  filters: {},
  tempFilters: {},
  dropDown: {},
  testedClaim: {},
  detail: undefined,
  tempFormula: undefined,
  pagination: {
    totalPages: 0,
    page: 1,
    pageSize: 10,
    total: 0
  },
  hasActionsAddon: true,
  isLoading: false,
  pkCodesDropdown: [],
  error: {},
  testedClaimTaxonomy: {}
}

const formulaSchemaSlice = createSlice({
  name: 'formulaschema',
  initialState,
  reducers: {
    setData: (
      state: FormulaSchemeState,
      { payload }: PayloadAction<{ data: FormulaCreateDto[] }>
    ) => {
      const { data } = payload
      state.items = data.map((rec) => ({
        ...rec,
        _formulaSchemaCode: rec.formulaSchemaCode,
        _formulaCodeDEVEX: rec.formula
          .map((f) => f.formulaCodeDEVEX)
          .flat()
          .filter(Boolean),
        _formulaCodeSAP: rec.formula
          .map((f) => f.formulaCodeSAP)
          .flat()
          .filter(Boolean),
        _formulaColor: rec.formula
          .map((f) => f.color)
          .flat()
          .filter(Boolean),
        _formulaStatus: rec.formula
          .map((f) => f.status)
          .flat()
          .filter(Boolean),
        _formulaName: rec.formula
          .map((f) => f.name)
          .flat()
          .filter(Boolean),
        _formulaLabReferenceCode: rec.formula
          .map((f) => f.labReferenceCode)
          .flat()
          .filter(Boolean)
      }))
      state.itemsByIds = keyBy(state.items, 'id')
    },
    setPagination: (
      state,
      {
        payload
      }: PayloadAction<{
        page: number
        pageSize?: number
        totalPages?: number
        total?: number
      }>
    ) => {
      state.pagination = { ...state.pagination, ...payload }
    },
    resetPagination: (state) => {
      state.pagination = initialState.pagination
    },
    changeRole: (state: FormulaSchemeState, { payload }: PayloadAction<string>) => {},
    setDetail: (
      state: FormulaSchemeState,
      { payload }: PayloadAction<FormulaSchemaDto | undefined>
    ) => {
      state.detail = payload
    },
    clearFilters: (state: FormulaSchemeState) => {
      state.filters = initialState.filters
      state.tempFilters = initialState.filters
      state.pagination = initialState.pagination
    },
    removeFilter: (state: FormulaSchemeState, { payload }: any) => {
      unset(state.filters, payload)
    },
    applyActionsAddon: (state: FormulaSchemeState) => {
      state.hasActionsAddon = true
    },
    removeActionsAddon: (state: FormulaSchemeState) => {
      state.hasActionsAddon = false
    },
    setDropdown: (state, { payload }) => {
      // @ts-ignore
      state.dropDown = Object.entries(payload).reduce(
        (acc, [k, v]) => ({
          ...acc,
          // @ts-ignore
          [k]: v.map((el: string) => ({ name: el, value: el }))
        }),
        {}
      )
    },
    tempToFilter: (state: FormulaSchemeState) => {
      const data = Object.entries(state.tempFilters).filter(([k, v]) =>
        v !== '' ? [k, v] : null
      )
      const filters = data.reduce((acc, [k, v]) => {
        return { ...acc, [k]: v }
      }, {})
      state.filters = filters
    },
    cancelFilter: (
      state: FormulaSchemeState,
      { payload }: PayloadAction<{ path: string }>
    ) => {
      const { path } = payload
      unset(state, path)
    },
    applyFilters: (state: FormulaSchemeState, { payload }: PayloadAction<any>) => {
      state.filters = payload
    },
    filterToTemp: (state: FormulaSchemeState) => {
      state.tempFilters = state.filters
    },
    updateTempFilters: (state: FormulaSchemeState, { payload }: PayloadAction<any>) => {
      state.tempFilters = payload
    },
    clearTempFilters: (state: FormulaSchemeState) => {
      state.tempFilters = initialState.tempFilters
    },
    setPKCodesDropdown: (state: FormulaSchemeState, { payload }: PayloadAction<any>) => {
      state.pkCodesDropdown = payload
    },
    setTestedClaimTaxonomy: (
      state: FormulaSchemeState,
      { payload }: PayloadAction<any>
    ) => {
      state.testedClaimTaxonomy = payload
    },
    setIsLoading: (state: FormulaSchemeState, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setError: (state: FormulaSchemeState, { payload }: PayloadAction<IError | {}>) => {
      state.error = getCleanError(payload)
    },
    clearError: (state: FormulaSchemeState) => {
      state.error = initialState.error
    },
    setTempDetail: (state: FormulaSchemeState) => {
      state.tempDetail = state.detail
    },
    updateTestedClaim: (
      state: FormulaSchemeState,
      { payload }: PayloadAction<{ key: string; value: any }>
    ) => {
      const { key, value } = payload
      if (state.testedClaim) {
        state.testedClaim = { ...state.testedClaim, [key]: value }
      }
    },
    setTestedClaim: (
      state: FormulaSchemeState,
      { payload }: PayloadAction<{ value: Record<string, any> }>
    ) => {
      state.testedClaim = payload
    },
    resetTestedClaim: (state: FormulaSchemeState) => {
      state.testedClaim = initialState.testedClaim
    },
    updateTempDetail: (
      state: FormulaSchemeState,
      { payload }: PayloadAction<{ key: string; value: string }>
    ) => {
      const { key, value } = payload
      if (state.tempDetail) {
        state.tempDetail = { ...state.tempDetail, [key]: value }
      }
    },
    setTempFormula: (state: FormulaSchemeState, { payload }: PayloadAction<Formula>) => {
      state.tempFormula = payload
    },
    updateTempFormula: (
      state: FormulaSchemeState,
      { payload }: PayloadAction<{ key: keyof Formula; value: string }>
    ) => {
      const { value, key } = payload
      if (state.tempFormula) {
        state.tempFormula = { ...state.tempFormula, [key]: value }
      }
    },
    updateIndustrialCode: (
      state: FormulaSchemeState,
      { payload }: PayloadAction<any>
    ) => {
      const newFormulas = state.detail?.formula.map((currentFormula) =>
        currentFormula.id === payload.id ? payload : currentFormula
      )
      set(state, 'detail.formula', newFormulas)
    }
  }
})

export default formulaSchemaSlice.reducer
export const fSchemaSliceActions = formulaSchemaSlice.actions
