import { get } from 'lodash'
import { createSelector } from 'reselect'
import type { RootState } from '../store'

const selectFormulaScheme = (state: RootState) => state.formulaschema

export const getItemsSchema = createSelector(selectFormulaScheme, (state) => state.items)

export const selectFSchemaError = createSelector(
  selectFormulaScheme,
  (state) => state.error
)

export const selectFSchemaIsLoading = createSelector(
  selectFormulaScheme,
  (state) => state.isLoading
)

export const selectFormulaSchemaPagination = createSelector(
  selectFormulaScheme,
  (state) => state.pagination
)

export const getSchemaById = createSelector(
  selectFormulaScheme,
  (state) => state.itemsByIds
)

export const getSchemaFilters = createSelector(
  selectFormulaScheme,
  (state) => state.filters
)

export const getSchemaTempFilters = createSelector(
  selectFormulaScheme,
  (state) => state.tempFilters
)

export const getFormulaItemsByIds = createSelector(
  selectFormulaScheme,
  (formulaschema) => formulaschema.itemsByIds
)

export const selectFormulaDetail = createSelector(
  selectFormulaScheme,
  (formulaschema) => formulaschema.detail
)

export const getFormulaById = (id: string) =>
  createSelector(getFormulaItemsByIds, (itemsByIds) => itemsByIds[id])

export const selectFormulaSchemaById = createSelector(selectFormulaDetail, (detail) =>
  get(detail, 'id', '')
)

export const selectFormulaSchemaCode = createSelector(selectFormulaDetail, (detail) =>
  get(detail, 'formulaSchemaCode')
)

export const selectFormula = createSelector(selectFormulaDetail, (detail) =>
  get(detail, 'formula')
)

export const selectFormulaSchemaDropdown = createSelector(
  selectFormulaScheme,
  (formulaschema) => formulaschema.dropDown
)

export const selectFormulaSchemaFilters = createSelector(
  selectFormulaScheme,
  (formulaschema) => formulaschema.filters
)

export const selectFormulaSchemaLaunchYear = createSelector(
  selectFormulaSchemaDropdown,
  (formulaschema) => get(formulaschema, 'launchYear')
)

export const selectFormulaSchemaFinish = createSelector(
  selectFormulaSchemaDropdown,
  (formulaschema) => get(formulaschema, 'fsFinish')
)

export const selectFormulaSchemaDevexApplicationArea = createSelector(
  selectFormulaSchemaDropdown,
  (formulaschema) => get(formulaschema, 'devexApplicationArea')
)

export const selectIndustrialCodeShelfLife = createSelector(
  selectFormulaSchemaDropdown,
  (formulaschema) => get(formulaschema, 'icShelfLife')
)

export const selectFormulaSchemaCollection = createSelector(
  selectFormulaSchemaDropdown,
  (formulaschema) => get(formulaschema, 'collection')
)

export const selectFormulaSchemaSpf = createSelector(
  selectFormulaSchemaFilters,
  (formulaschema) => get(formulaschema, 'fsSpf')
)

export const selectFormulaSchemaOtc = createSelector(
  selectFormulaSchemaFilters,
  (formulaschema) => get(formulaschema, 'otc')
)

export const selectHasActionsAddon = createSelector(
  selectFormulaScheme,
  (state) => state.hasActionsAddon
)

export const selectFormulaTempDetail = createSelector(
  selectFormulaScheme,
  (formulaschema) => formulaschema.tempDetail
)

export const selectTestedClaim = createSelector(
  selectFormulaScheme,
  (formulaschema) => formulaschema.testedClaim
)

export const selectFSchemaTempFormula = createSelector(
  selectFormulaScheme,
  (formulaschema) => formulaschema.tempFormula
)

export const selectPkCodesDropdown = createSelector(
  selectFormulaScheme,
  (formulaschema) => formulaschema.pkCodesDropdown
)

export const selectTestedClaimTaxonomy = createSelector(
  selectFormulaScheme,
  (formulaschema) => formulaschema.testedClaimTaxonomy
)
