import { apiClient } from '../../api/client'

import { IDynamicFiltersPayload, ProductCreateDto } from './model'

export const createProductNew = (data: any) => {
  return apiClient.post<ProductCreateDto>('v2/product', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const getProductTaxonomies = () => {
  return apiClient.get<Record<string, any>>('product/taxonomies')
}

export const getProductDynamicTaxonomies = (data?: IDynamicFiltersPayload) => {
  return apiClient.post<Record<string, any>>('v2/product/taxonomies', data)
}
