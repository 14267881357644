import { TABLE_DATA_TYPE } from '../../components/Table/constants'
import { ITableConfig } from '../../components/Table/types'

export const OTHER_TESTED_PACKAGING_COLUMNS: ITableConfig = {
  id: {
    label: 'id',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'id',
    hide: true
  },
  formulaSchemaId: {
    label: 'Formula Schema id',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'formulaSchemaId',
    hide: true
  },
  formulaSchema: {
    label: 'formulaSchema',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'formulaSchema',
    hide: true
  },
  sideCollectorId: {
    label: 'Side Collector Id',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'sideCollectorId',
    hide: true
  },
  testNumber: {
    label: 'Test Number',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'testNumber'
  },
  testedCode: {
    label: 'Tested Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'testedCode'
  },
  testStartDate: {
    label: 'Test Start Date',
    dataType: TABLE_DATA_TYPE.DATE,
    dataPath: 'testStartDate'
  },
  testResultInView: {
    label: 'Test Result',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'testResultInView'
  },
  testResult: {
    label: 'Test Result',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'testResult',
    hide: true
  },
  weightLossTestInView: {
    label: 'Weight Loss Test',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'weightLossTestInView'
  },
  weightLossTest: {
    label: '',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'weightLossTest',
    hide: true
  },
  minPercentVolatilesInSpecific: {
    label: 'Min % Volatiles in Specific',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'minPercentVolatilesInSpecific'
  },
  maxPercentVolatilesInSpecific: {
    label: 'Max % Volatiles in Specific',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'maxPercentVolatilesInSpecific'
  },
  materials: {
    label: 'Materials',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'materials'
  },
  packagingCode: {
    label: 'PK Intercos Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'packagingCode'
  },
  category: {
    label: 'Packaging Category',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'category'
  }
}

export const OTHER_TESTED_PACKAGING_COLUMNS_IN_PRODUCT: ITableConfig = {
  id: {
    label: 'id',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'id',
    hide: true
  },
  packagingCode: {
    label: 'PK Intercos Code',
    dataType: TABLE_DATA_TYPE.NODE,
    dataPath: 'packagingCode'
  },
  category: {
    label: 'Packaging Category',
    dataType: TABLE_DATA_TYPE.NODE,
    dataPath: 'category'
  },
  productCode: {
    label: 'Product Code',
    dataType: TABLE_DATA_TYPE.NODE,
    dataPath: 'productCode'
  },
  pdLaunchYear: {
    label: 'PD Launch Year',
    dataType: TABLE_DATA_TYPE.NODE,
    dataPath: 'pdLaunchYear'
  },
  pdCollection: {
    label: 'PD Collection',
    dataType: TABLE_DATA_TYPE.NODE,
    dataPath: 'pdCollection'
  },
  formulaSchemaId: {
    label: 'Formula Schema id',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'formulaSchemaId',
    hide: true
  },
  formulaSchema: {
    label: 'formulaSchema',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'formulaSchema',
    hide: true
  },
  sideCollectorId: {
    label: 'Side Collector Id',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'sideCollectorId',
    hide: true
  },
  testNumber: {
    label: 'Test Number',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'testNumber'
  },
  testedCode: {
    label: 'Tested Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'testedCode'
  },
  testStartDate: {
    label: 'Test Start Date',
    dataType: TABLE_DATA_TYPE.DATE,
    dataPath: 'testStartDate'
  },
  testResult: {
    label: 'Test Result',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'testResult'
  },
  weightLossTest: {
    label: 'Weight Loss Test',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'weightLossTest'
  },
  minPercentVolatilesInSpecific: {
    label: 'Min % Volatiles in Specific',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'minPercentVolatilesInSpecific'
  },
  maxPercentVolatilesInSpecific: {
    label: 'Max % Volatiles in Specific',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'maxPercentVolatilesInSpecific'
  },
  materials: {
    label: 'Materials',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'materials'
  }
}

export const TESTED_CLAIM_COLUMNS: ITableConfig = {
  id: {
    label: 'id',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'id',
    hide: true
  },
  formulaSchemaId: {
    label: 'Formula Schema id',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'formulaSchemaId',
    hide: true
  },
  formulaSchema: {
    label: 'formulaSchema',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'formulaSchema',
    hide: true
  },
  sideCollectorId: {
    label: 'Side Collector Id',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'sideCollectorId',
    hide: true
  },
  type: {
    label: 'Test/Extension',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'type'
  },
  testedCode: {
    label: 'Tested Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'testedCode'
  },
  claim: {
    label: 'Claim',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'claim'
  },
  testType: {
    label: 'Test Type',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'testType'
  },
  claimResult: {
    label: 'Claim Result',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'claimResult'
  },
  reportDate: {
    label: 'Report Date',
    dataType: TABLE_DATA_TYPE.DATE,
    dataPath: 'reportDate'
  },
  testStatesCustomerName: {
    label: 'Test States Customer Name',
    dataType: TABLE_DATA_TYPE.BOOL,
    dataPath: 'testStatesCustomerName'
  },
  reportNumber: {
    label: 'Report Number',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'reportNumber'
  },
  testMethod: {
    label: 'Test Method',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'testMethod'
  },
  note: {
    label: 'Note',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'note'
  }
}
